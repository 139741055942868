export default class HistoryState {
  constructor() {
    this.history = [];
    this.currentPointer = -1;
  }

  setHistory(newState) {
    if (this.currentPointer++ == this.history.length - 1) {
      this.history = [...this.history, newState];
    } else {
      this.history = [...this.history.slice(0, this.currentPointer), newState];
    }
  }

  canUndo() {
    return this.currentPointer > 0;
  }

  canRedo() {
    return (
      this.currentPointer > -1 && this.currentPointer < this.history.length - 1
    );
  }

  undo() {
    if (!this.canUndo()) return;

    this.currentPointer--;
    return this.history[this.currentPointer];
  }

  redo() {
    if (!this.canRedo()) return;

    this.currentPointer++;
    return this.history[this.currentPointer];
  }
}
