export const defaultPlayers = [
  {
    position: '1B',
    x: 265,
    y: 213,
  },
  {
    position: '2B',
    x: 230,
    y: 138,
  },
  {
    position: 'SS',
    x: 120,
    y: 138,
  },
  {
    position: '3B',
    x: 90,
    y: 213,
  },
  {
    position: 'P',
    x: 175,
    y: 236.5,
  },
  {
    position: 'C',
    x: 175,
    y: 342,
  },
  {
    position: 'LF',
    x: 60,
    y: 65,
  },
  {
    position: 'CF',
    x: 175,
    y: 30,
  },
  {
    position: 'RF',
    x: 290,
    y: 65,
  },
];

export const runnerPositions = {
  '1BR': {
    position: 'R',
    x: 230,
    y: 220,
  },
  '2BR': {
    position: 'R',
    x: 145,
    y: 170,
  },
  '3BR': {
    position: 'R',
    x: 105,
    y: 255,
  },
  RH: {
    position: 'B',
    x: 153,
    y: 315,
  },
  LH: {
    position: 'B',
    x: 200,
    y: 315,
  },
};
