import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="recorder"
export default class extends Controller {
  static targets = [
    'prompt',
    'countdown',
    'recordButton',
    'stopButton',
    'timer',
    'formVideo',
    'form',
  ];

  static values = {
    cameraDirection: String,
  };

  async connect() {
    this.elapsedTime = 0;
    this.localVideo = document.querySelector('video');
    this.chunks = [];
    this.mediaRecorder;
    this.setMimeType();

    this.mediaStream = await this.recordScreen();
    this.mediaRecorder = new MediaRecorder(this.mediaStream, {
      mimetype: this.mimetype,
    });

    this.setListeners();
  }

  disconnect() {
    this.mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  setMimeType() {
    if (MediaRecorder.isTypeSupported('video/mp4')) {
      this.mimetype = 'video/mp4';
      this.fileExtension = 'mp4';
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      this.mimetype = 'video/webm';
      this.fileExtension = 'webm';
    } else {
      alert('Error: Video format not supported');
    }
  }

  start() {
    let countdownNumber = 3;
    this.promptTarget.classList.add('hidden');
    this.countdownTarget.innerText = countdownNumber--;

    let countdown = setInterval(() => {
      if (countdownNumber === 0) {
        this.startRecording(countdown);
      }

      this.countdownTarget.innerText = countdownNumber--;
    }, 1000);
  }

  stop() {
    clearInterval(this.timer);
    this.mediaRecorder.stop();
  }

  startRecording(countdown) {
    clearInterval(countdown);
    this.countdownTarget.classList.add('hidden');
    this.recordButtonTarget.classList.add('hidden');
    this.stopButtonTarget.classList.remove('hidden');

    this.startTimer();
    this.mediaRecorder.start();
  }

  startTimer() {
    this.timer = setInterval(() => {
      const time = ++this.elapsedTime;
      const seconds = time % 60;
      const minutes = parseInt(time / 60);

      this.timerTarget.innerText = `${this.formatTime(
        minutes
      )}:${this.formatTime(seconds)}`;
    }, 1000);
  }

  formatTime(timeCount) {
    if (timeCount >= 10) return timeCount;

    return `0${timeCount}`;
  }

  flipCamera() {
    this.cameraDirectionValue =
      this.cameraDirectionValue === 'environment' ? 'user' : 'environment';

    this.recordScreen();
  }

  async recordScreen() {
    const constraints = {
      audio: false,
      video: {
        facingMode: this.cameraDirectionValue,
      },
    };

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    this.localVideo.srcObject = stream;

    return stream;
  }

  setListeners() {
    this.mediaRecorder.ondataavailable = this.handleDataAvailable;
    this.mediaRecorder.onstop = this.handleStop;
  }

  destroyListeners() {
    this.mediaRecorder.ondataavailable = null;
    this.mediaRecorder.onstop = null;
  }

  handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      this.chunks.push(data);
    }
  };

  handleStop = () => {
    this.saveFile();

    this.destroyListeners();
    this.mediaRecorder = undefined;
  };

  saveFile() {
    const blob = new Blob(this.chunks);
    const dataTransfer = new DataTransfer();
    const file = new File([blob], `recorded-sign.${this.fileExtension}`);

    dataTransfer.items.add(file);
    this.formVideoTarget.files = dataTransfer.files;

    this.formTarget.submit();
  }
}
