import BulkController from "./bulk_controller.js";

const hiddenClass = "hidden";

export default class extends BulkController {
  static targets = ["submitButton", "disabledMessage"];

  connect() {
    this.updateText();
  }

  // Wrapper function to also call `updateText` when clicking selectAll
  clickSelectAll(event) {
    this.toggleSelectAll(event);
    this.updateText(event);
  }

  clickSelectCategory(event) {
    const categoryId = event.target.dataset.categoryId;

    this.unselectAll();
    this.checkboxTargets
        .filter(checkbox => checkbox.dataset.categoryId === categoryId)
        .forEach(checkbox => checkbox.checked = true);
    this.updateText(event);
  }

  // Update targets based on checkbox state
  updateText(event) {
    if (this.allCheckboxesSelected) {
      this.selectAllTarget.innerText = "Deselect all plays";
    } else {
      this.selectAllTarget.innerText = "Select all plays";
    }

    if (this.anyCheckboxSelected) {
      this.disabledMessageTarget.classList.add(hiddenClass);
      this.submitButtonTarget.disabled = false;
    } else {
      this.disabledMessageTarget.classList.remove(hiddenClass);
      this.submitButtonTarget.disabled = true;
    }
  }

  // Returns true if any checkbox is checked
  get anyCheckboxSelected() {
    return this.checkboxTargets.some(target => target.checked)
  }
}
