import Player from './player';

export default class DefensivePlayer extends Player {
  constructor({ position, x, y, playCreator, group }) {
    super({
      position,
      x,
      y,
      playCreator,
      visible: true,
      fillColor: '#F84E30',
      strokeColor: '#F84E3080',
      fontColor: 'white',
      group: group,
    });
  }
}
