import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "overflow-menu"

  connect() {
    super.connect()
    console.log("********** CONNECTED TO BRIDGE **********")
    this.notifyBridgeOfConnect()
  }

  notifyBridgeOfConnect() {
    const label = this.bridgeElement.title
    const avatarUrl = this.bridgeElement.bridgeAttribute("avatarUrl")
    console.log(`Avatar data: ${avatarUrl}`)

    this.send("connect", { label, avatarUrl }, () => {
      this.bridgeElement.click()
    })
  }
}
