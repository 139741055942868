import { Controller } from '@hotwired/stimulus';
import PlayCreator from '../src/play_creator/play_creator';

// Connects to data-controller="play-creator"
export default class extends Controller {
  static targets = [
    'taskbar',
    'action',
    'backArrow',
    'forwardArrow',
    'formImage',
    'canvasJSON',
    'form',
    'drawBall',
    'endDrawBall',
    'ballTray',
    'ball',
  ];
  static values = {
    fieldImage: String,
    canvas: Object,
  };

  connect() {
    this.playCreator = new PlayCreator(
      this.fieldImageValue,
      this.canvasValue,
      this.endDrawBall.bind(this)
    );
    this.moving = false;
    this.checkRunnerToggle();
  }

  undo() {
    this.checkRunnerToggle();
    this.playCreator.undo();
  }

  redo() {
    this.checkRunnerToggle();
    this.playCreator.redo();
  }

  checkRunnerToggle() {
    ['1BR', '2BR', '3BR', 'LH', 'RH'].forEach((position) => {
      const element = document.querySelector(`[data-position="${position}"]`);
      this.toggleRunnerElement(element, position);
    });
  }

  setActiveBall(e) {
    const ballTarget = e.currentTarget.dataset.ball;
    this.ballTargets.forEach((target) => {
      target.classList.remove('border-white');
      target.classList.add('border-transparent');
    });

    e.currentTarget.classList.remove('border-transparent');
    e.currentTarget.classList.add('border-white');

    this.playCreator.setActiveBall(ballTarget);
  }

  deleteActiveBall() {
    this.playCreator.deleteActiveBall();
  }

  drawBall() {
    this.actionTarget.innerText = 'Editing: Ball Path';

    this.startDrawBall();
    this.playCreator.toggleDrawingBall();
  }

  isSingleBall() {
    const ballGroupIds = this.playCreator.balls.map(ball => ball.group)
      .filter(group => group)
      .map(group => group.attrs.id);

    if (ballGroupIds.length > 0) {
      // Returns true if a non-indexed ballGroup ID is found.
      return ballGroupIds.includes("ballGroup");
    } else {
      // Fallback to the previous check if no groups are present.
      return this.playCreator.balls.length <= 1;
    }
  }

  startDrawBall() {
    if (this.playCreator.drawingBall) return;

    if (this.isSingleBall()) {
      this.drawBallTarget.classList.add('border-white');
      this.drawBallTarget.classList.remove('border-transparent');
      this.drawBallTarget.innerText = 'Done';
    } else {
      this.ballTrayTarget.classList.remove('hidden');
      this.taskbarTarget.classList.add('hidden');
    }
  }

  endDrawBall() {
    if (this.isSingleBall()) {
      this.drawBallTarget.classList.remove('border-white');
      this.drawBallTarget.classList.add('border-transparent');
      this.drawBallTarget.innerText = 'Draw';
    } else {
      this.ballTrayTarget.classList.add('hidden');
      this.taskbarTarget.classList.remove('hidden');
    }

    if (this.moving) {
      this.actionTarget.innerText = 'Editing: Player Movement';
    } else {
      this.actionTarget.innerText = 'Editing: Player Location';
    }
  }

  toggleRunner({ currentTarget }) {
    const position = currentTarget.dataset.position;
    this.toggleOtherBatterElementOff(position);
    this.playCreator.toggleOffensivePlayer(position);
    this.toggleRunnerElement(currentTarget, position);
  }

  toggleRunnerElement(element, position) {
    if (this.playCreator.runners[position].visible) {
      element.classList.remove('border-transparent');
      element.classList.add(this.borderColorClass);
    } else {
      element.classList.remove(this.borderColorClass);
      element.classList.add('border-transparent');
    }
  }

  toggleOtherBatterElementOff(position) {
    if (position == 'LH') {
      const batter = this.element.querySelector("[data-position='RH']")
      batter.classList.remove(this.borderColorClass);
      batter.classList.add('border-transparent');
    } else if (position == 'RH') {
      const batter = this.element.querySelector("[data-position='LH']")
      batter.classList.remove(this.borderColorClass);
      batter.classList.add('border-transparent');
    }
  }

  togglePositionTwo({ currentTarget }) {
    this.moving = !this.moving;
    if (this.moving) {
      currentTarget.querySelector('span').innerText = 'Player Location';
      this.actionTarget.innerText = 'Editing: Player Movement';
      this.forwardArrowTarget.classList.add('opacity-0');
      this.backArrowTarget.classList.remove('opacity-0');
      this.playCreator.editPositionTwo();
    } else {
      currentTarget.querySelector('span').innerText = 'Player Movement';
      this.actionTarget.innerText = 'Editing: Player Location';
      this.forwardArrowTarget.classList.remove('opacity-0');
      this.backArrowTarget.classList.add('opacity-0');
      this.playCreator.editPositionOne();
    }
  }

  lastSaveReset() {
    this.reset(this.canvasValue);
  }

  fullReset() {
    this.reset({});
  }

  reset(canvas) {
    this.playCreator = new PlayCreator(
      this.fieldImageValue,
      canvas,
      this.endDrawBall.bind(this)
    );
    this.moving = false;
    this.checkRunnerToggle();
    this.endDrawBall();
  }

  save() {
    const dataURL = this.playCreator.stage.toDataURL({ pixelRatio: 2 });
    const dataTransfer = new DataTransfer();
    const file = new File([this.dataURItoBlob(dataURL)], 'play_creator.png');

    dataTransfer.items.add(file);
    this.formImageTarget.files = dataTransfer.files;

    this.canvasJSONTarget.value = this.playCreator.stageJSON;
    this.formTarget.requestSubmit();
  }

  get borderColorClass() {
    return "border-[#3b82f680]";
  }

  // From StackOverflow: https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }
}
