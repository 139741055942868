import Konva from 'konva';

const offset = 48;
export default class Player {
  constructor({
    position,
    positionText,
    x,
    y,
    playCreator,
    visible,
    fillColor,
    strokeColor,
    fontColor,
    group,
  }) {
    this.position = position;
    this.positionText = this.formatString(positionText || position);
    this.visible = visible;
    this.fillColor = fillColor;
    this.strokeColor = strokeColor;
    this.fontColor = fontColor;
    this.playCreator = playCreator;
    this._x = x;
    this._y = y;
    this.locked = false;
    this.diameter = 20;
    this.fontSize = 10;
    this.group = group || this.createGroup();

    if (group) return;
    this.createPlayer();
  }

  get x() {
    return this._x;
  }

  set x(newX) {
    this._x = Math.min(340, Math.max(10, newX));
    this.group.x(this._x);
  }

  get y() {
    return this._y;
  }

  set y(newY) {
    const min = this.touchMove ? 390 + offset : 390;
    this._y = Math.min(min, Math.max(10, newY));
    this.group.y(this._y);
  }

  reset(newGroup) {
    this.group = newGroup;
    this._x = this.group.x();
    this._y = this.group.y();
    this.resetListeners();
  }

  resetListeners() {
    this.group.off('mousedown touchstart');
    this.group.off('dragmove');
    this.group.off('touchend');

    this.addCursorStyling();
    this.addDragListeners();
    this.addOffsetHandlers();

    if (this.locked) {
      this.switchIndicateMovement();
    }
  }

  switchDrag() {
    this.locked = false;
    this.group.setAttrs({ draggable: true });

    this.resetListeners();
  }

  switchIndicateMovement() {
    this.locked = true;
    this.group.setAttrs({ draggable: false });

    this.addArrowHandlers();
  }

  createGroup() {
    return new Konva.Group({
      x: this.x,
      y: this.y,
      id: this.position,
      width: this.diameter,
      height: this.diameter,
      draggable: true,
      visible: this.visible,
    });
  }

  createPlayer() {
    this.createLargeTouchTarget();

    this.group.add(
      new Konva.Circle({
        x: 0,
        y: 0,
        width: this.diameter,
        height: this.diameter,
        fill: this.fillColor,
        stroke: this.strokeColor,
        strokeWidth: 4,
      })
    );

    this.addPositionText();
    this.addCursorStyling();
    this.addDragListeners();
    this.addOffsetHandlers();
  }

  createLargeTouchTarget() {
    this.group.add(
      new Konva.Circle({
        x: 0,
        y: 0,
        width: this.diameter * 2,
        height: this.diameter * 2,
      })
    );
  }

  addPositionText() {
    this.group.add(
      new Konva.Text({
        x: -(this.fontSize * 0.56), // Percentage helps offset the font size to center the coordinates
        y: -(this.fontSize * 0.43),
        text: this.positionText,
        fontSize: this.fontSize,
        fontFamily: 'Inter',
        fill: this.fontColor,
        draggable: false,
        listening: false,
      })
    );
  }

  formatString(string) {
    return string.length < 2 ? ` ${string}` : string.slice(0, 2);
  }

  addCursorStyling() {
    this.group.on('mouseover', function () {
      document.body.style.cursor = 'pointer';
    });

    this.group.on('mouseout', function () {
      document.body.style.cursor = 'default';
    });
  }

  addArrowHandlers() {
    this.handleArrowStart();
    this.handleArrowDrag();
    this.handleArrowEnd();
  }

  handleArrowStart() {
    const { layer } = this.playCreator;

    this.group.on('mousedown touchstart', (e) => {
      if (this.playCreator.drawingBall) return;

      this.group.findOne('#arrow')?.destroy();

      this.arrow = new Konva.Arrow({
        points: [0, 0],
        id: 'arrow',
        stroke: this.fillColor,
        fill: this.fillColor,
      });

      this.group.add(this.arrow);
      this.arrow.moveToBottom();
      layer.batchDraw();

      if (e.type.includes('touch')) this.touchMove = true;
    });
  }

  addDragListeners() {
    this.handleDragStart();
    this.handleDragMove();
  }

  handleDragStart() {
    this.group.on('dragstart', () => {
      if (this.playCreator.drawingBall) return this.group.stopDrag();

      this.group.moveToTop();
    });
  }

  handleDragMove() {
    this.group.on('dragmove', () => {
      this.x = this.group.x();
      this.y = this.group.y();
    });
  }

  handleArrowDrag() {
    const { stage, layer } = this.playCreator;

    stage.on('mousemove touchmove', () => {
      if (this.arrow) {
        const pos = stage.getPointerPosition();
        const parentX = this.arrow.getParent().x();
        const parentY = this.arrow.getParent().y();
        const yMin = this.touchMove ? offset : 0;
        const posY = this.touchOffset(
          Math.min(450, Math.max(yMin, pos.y)) - parentY
        );
        const points = [
          this.arrow.points()[0],
          this.arrow.points()[1],
          Math.min(350, Math.max(0, pos.x)) - parentX,
          posY,
        ];
        this.arrow.points(points);
        layer.batchDraw();
      }
    });
  }

  handleArrowEnd() {
    const { stage, layer, history } = this.playCreator;

    stage.on('mouseup touchend', () => {
      if (!this.arrow) return;

      history.setHistory(layer.toJSON());
      this.arrow = null;
      this.touchMove = null;
    });
  }

  addOffsetHandlers() {
    this.handleTouchstart();
    this.handleTouchDragMove();
    this.handleTouchend();
  }

  handleTouchstart() {
    this.group.on('touchstart', () => {
      if (this.locked || this.playCreator.drawingBall) return;

      this.touchMove = true;
      this.y = this.touchOffset(this.y);
    });
  }

  handleTouchDragMove() {
    this.group.on('dragmove', () => {
      if (this.locked || !this.touchMove) return;

      this.y = this.touchOffset(this.group.y());
    });
  }

  handleTouchend() {
    this.group.on('touchend', () => {
      if (this.locked) return;

      this.y = this.touchOffset(this.group.y());
      this.touchMove = null;
    });
  }

  touchOffset(pos) {
    if (!this.touchMove) return pos;
    return pos - offset;
  }
}
