import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["active", "help"];

  connect() {
    this.updateNotificationSettings();
  }

  requestPermissions() {
    const controller = this;

    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: window.vapidPublicKey,
        })
        .then(function(subscription) {
          fetch("/register_push_subscription", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(subscription),
          }).then((res) => {
            console.log("Controller Request complete! response:", res);
            controller.updateNotificationSettings();
          });
          console.log(JSON.stringify(subscription));
        })
        .catch((e) => {
          console.log(e);
          controller.updateNotificationSettings();
        });
      console.log("PushManager loaded....");
    });
  }

  updateNotificationSettings() {
    const permission =
      ("Notification" in window && Notification.permission) || "unknown";

    this.activeTarget.disabled = ["granted", "denied", "unknown"].includes(
      permission
    );

    if (permission === "granted") {
      this.activeTarget.innerText = "Push Notifications Enabled";
    } else if (permission === "denied") {
      this.activeTarget.innerText = "Push Notifications Disabled";
    } else if (permission === "unknown") {
      this.activeTarget.innerText = "Push Notifications Unavailable";
    }

    this.helpTarget.classList.toggle(
      "hidden",
      ["default", "unknown"].includes(permission)
    );
  }
}
