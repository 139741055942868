import Player from './player';

export default class OffensivePlayer extends Player {
  constructor({
    position,
    positionText,
    x,
    y,
    playCreator,
    visible = false,
    group,
  }) {
    super({
      position,
      positionText,
      x,
      y,
      playCreator,
      visible,
      fillColor: '#00D2FF',
      strokeColor: '#00D2FF80',
      fontColor: 'black',
      group: group,
    });
  }

  toggle() {
    this.visible = !this.visible;
    this.group.visible(this.visible);
  }
}
