import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["countrySelect", "stateSelect"];

  load() {
    const countryCode = this.countrySelectTarget.value || "US";

    Array.from(this.stateSelectTarget.children).forEach((option) => {
      this.stateSelectTarget.removeChild(option);
    });

    fetch(`/${countryCode}/states`)
      .then((response) => response.text())
      .then((text) => JSON.parse(text))
      .then((states) => {
        const options = Object.keys(states).map((label) => {
          const value = states[label];
          const option = new Option(value, label);

          return option;
        });
        const blankOption = new Option("", "");

        return [blankOption, ...options];
      })
      .then((options) => {
        options.forEach((item) => {
          this.stateSelectTarget.appendChild(item);
        });
      })
      .catch((err) => console.error(err));
  }
}
