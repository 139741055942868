import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["back", "front"];

  connect() {
    this.flipToFront();
  }

  flipToBack() {
    this.frontTargets.forEach(this.hideElement);
    this.backTargets.forEach(this.showElement);
  }

  flipToFront() {
    this.frontTargets.forEach(this.showElement);
    this.backTargets.forEach(this.hideElement);
  }

  showElement(element) {
    element.classList.remove("hidden");
    element.classList.add("flex");
  }

  hideElement(element) {
    element.classList.add("hidden");
  }
}
