// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from '@hotwired/stimulus';
import videojs from "video.js";

export default class extends Controller {
  connect() {
    this.videojs = videojs(this.element);

    this.videojs.ready(function() {
      this.videojs.requestFullscreen();
    });

    this.videojs.on("ended", function() {
      // Immediately leave fullscreen
      this.leaveFullscreen();

      // Also, request to leave fullscreen after a delay in case the earlier call didn't have the intended effect.
      setTimeout(this.leaveFullscreen.bind(this), 500);
    }.bind(this));
  }

  disconnect() {
    this.leaveFullscreen();
  }

  leaveFullscreen() {
    if (this && this.videojs && this.videojs.isFullscreen()) {
      this.videojs.exitFullscreen();
    }
  }
}
