import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="upload-progress"
export default class extends Controller {
  static targets = ['progress', 'progressText', 'progressWidth'];

  connect() {
    this.element.addEventListener(
      'direct-upload:progress',
      this.updateProgress.bind(this)
    );

    this.element.addEventListener('direct-upload:error', (event) => {
      event.preventDefault();
      const { error } = event.detail;
      console.log(error);
    });
  }

  showProgress() {
    this.progressTarget.classList.remove('hidden');
  }

  updateProgress(event) {
    const { progress } = event.detail;
    this.progressTextTarget.innerHTML = `${Math.round(progress)}% complete`;
  }

  disconnect() {
    this.element.removeEventListener(
      'direct-upload:progress',
      this.updateProgress
    );
  }
}
