import { BridgeComponent, BridgeElement } from "@hotwired/strada"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends BridgeComponent {
  static component = "team-menu"
  static targets = [ "title", "item" ]

  show(event) {
    if (this.enabled) {
      event.stopImmediatePropagation()
      this.notifyBridgeToDisplayMenu(event)
    }
  }

  notifyBridgeToDisplayMenu(event) {
    const title = new BridgeElement(this.titleTarget).title
    const items = this.makeMenuItems(this.itemTargets)

    this.send("display", { title, items }, message =>  {
      const selectedIndex = message.data.selectedIndex
      const selectedItem = new BridgeElement(this.itemTargets[selectedIndex])

      const link = selectedItem.bridgeAttribute("team-menu-link")
      Turbo.visit(link)
    })
  }

  makeMenuItems(elements) {
    const items = elements.map((element, index) => this.menuItem(element, index))
    const enabledItems = items.filter(item => item)

    return enabledItems
  }

  menuItem(element, index) {
    const bridgeElement = new BridgeElement(element)

    if (bridgeElement.disabled) return null

    return {
      title: bridgeElement.title,
      index: index,
      affiliationKind: bridgeElement.bridgeAttribute("affiliation-kind"),
      link: bridgeElement.bridgeAttribute("team-menu-link")
    }
  }
}
