import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["submitButton"];

  enableSubmit() {
    this.element.querySelector('button[type="submit"]').disabled = false;
  }

  submit() {
    const buttons = this.element.querySelectorAll('a, .button-default');

    buttons.forEach((button) =>
      button.classList.add('cursor-not-allowed', 'pointer-event-none')
    );

    this.submitButtonTarget.click();
  }
}
