import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="scroll"
export default class extends Controller {
  initialize() {
    this.handleTouch = this.throttle(this.handleTouch, 100).bind(this);
  }

  handleClick(event) {
    const href = event.currentTarget.dataset.href;

    document.querySelector(href).scrollIntoView();
  }

  handleTouch(event) {
    const touch = event.touches[0];
    const anchorLink = document.elementFromPoint(touch.clientX, touch.clientY);
    const tag = document.querySelector(anchorLink?.dataset.href);

    if (tag) {
      tag.scrollIntoView();
    }
  }

  throttle(cb, delay) {
    let wait = false;

    return (...args) => {
      if (wait) {
        return;
      }

      cb(...args);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, delay);
    };
  }
}
