import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "team-dropdown-menu"

  connect() {
    super.connect()
    this.notifyBridgeOfConnect()
  }

  notifyBridgeOfConnect() {
    const label = this.bridgeElement.bridgeAttribute("team-name")
    const affiliationKind = this.bridgeElement.bridgeAttribute("affiliation-kind")

    this.send("connect", { label, affiliationKind }, () => {
      this.bridgeElement.click()
    })
  }
}
