import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  hideOthers(event) {
    const active = event.target.closest("details");

    this.containerTargets.forEach(target => {
      if (active != target) {
        target.open = false;
      }
    });
  }
}
