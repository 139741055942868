import Konva from 'konva';

const offset = 40;
const colors = ['green', 'orange', 'rgb(83 126 243)'];

export default class Ball {
  constructor({ id, playCreator, group }) {
    this.playCreator = playCreator;
    this.group = group;
    this.radius = 8;
    this.ballCount = 0;
    this.id = id;

    this.setBallListeners();
    this.additionalBallPaths = false;
  }

  reset(group) {
    this.group = group;

    this.ballCount = this.group.find('.ball').length;
    const lastBall = this.group.findOne(`#ball${this.ballCount}`);
    this.handleMultipleBalls(lastBall);
  }

  deleteBall() {
    this.ballCount = 0;
    this.group?.destroy();
  }

  createBall(pos) {
    const outerPos = this.radius * Math.cos(Math.PI / 4);

    const ball = new Konva.Group({
      x: pos.x,
      y: pos.y,
      id: `ball${++this.ballCount}`,
      name: 'ball',
    });

    const largeTouchTarget = new Konva.Circle({
      x: 0,
      y: 0,
      radius: this.radius * 2,
    });

    const circle = new Konva.Circle({
      x: 0,
      y: 0,
      radius: this.radius,
      fill: '#FFF',
    });

    const leftArc = new Konva.Line({
      points: [-outerPos, -outerPos, -3.5, 0, -outerPos, outerPos],
      tension: 0.5,
      stroke: 'red',
      strokeWidth: 2,
    });

    const rightArc = new Konva.Line({
      points: [outerPos, -outerPos, 3.5, 0, outerPos, outerPos],
      tension: 0.5,
      stroke: 'red',
      strokeWidth: 2,
    });

    ball.add(largeTouchTarget);
    ball.add(circle);
    ball.add(leftArc);
    ball.add(rightArc);

    this.handleMultipleBalls(ball);

    this.group.add(ball);
  }

  setBallListeners() {
    this.handleDragStart();
    this.handleDragMove();
    this.handleDragEnd();
  }

  handleMultipleBalls(ball) {
    ball.on('mousedown touchstart', (e) => {
      if (this.shouldDrawBall()) {
        this.additionalBallPaths = true;
        this.ballArrow = this.drawArrow(ball.position());

        if (e.type.includes('touch')) this.touchMove = true;
      }
    });
  }

  handleDragStart() {
    const { stage } = this.playCreator;

    stage.on('mousedown touchstart', (e) => {
      if (this.shouldDrawBall() && !this.additionalBallPaths) {
        this.deleteBall();
        this.group = new Konva.Group({
          id: `ballGroup:${this.id}`,
          name: 'ballGroup'
        });

        const pos = stage.getPointerPosition();

        this.ballArrow = this.drawArrow(pos);

        if (e.type.includes('touch')) this.touchMove = true;
      }
    });
  }

  handleDragMove() {
    const { stage } = this.playCreator;

    stage.on('mousemove touchmove', () => {
      if (
        this.shouldDrawBall() &&
        (this.ballArrow || this.additionalBallPaths)
      ) {
        if (!this.group) return;

        const ball = this.group.findOne(`#ball${this.ballCount}`);

        this.dragArrow(ball, this.ballArrow);
      }
    });
  }

  clamp(pos, min, max) {
    return Math.min(max, Math.max(min, pos));
  }

  handleDragEnd() {
    const { stage, layer, history } = this.playCreator;

    stage.on('mouseup touchend', (e) => {
      if (this.shouldDrawBall()) {
        this.ballArrow = null;

        history.setHistory(layer.toJSON());

        if (e.type.includes('touch')) this.touchMove = false;

        if (this.additionalBallPaths) {
          this.additionalBallPaths = false;
        }
      }
    });
  }

  touchOffset(pos) {
    if (!this.touchMove) return pos;
    return pos - offset;
  }

  drawArrow(pos) {
    const { layer } = this.playCreator;

    const ballArrow = new Konva.Arrow({
      points: [pos.x, pos.y],
      stroke: colors[this.id - 1],
      fill: colors[this.id - 1],
      dash: [this.radius, this.radius * 0.75],
    });

    this.group.add(ballArrow);
    // Places arrow behind last drawn ball
    ballArrow.moveToBottom();
    this.removeLastBallEvents();
    this.createBall(pos);

    layer.add(this.group);
    layer.batchDraw();

    return ballArrow;
  }

  removeLastBallEvents() {
    const lastBall = this.group.findOne(`#ball${this.ballCount}`);
    lastBall?.off('mousedown touchstart');
  }

  shouldDrawBall() {
    return (
      this.playCreator.activeBall == this.id && this.playCreator.drawingBall
    );
  }

  dragArrow(ball, ballArrow) {
    const { stage, layer } = this.playCreator;

    if (!this.group || !ballArrow) return;

    const ballArrowX = ballArrow.points()[0];
    const ballArrowY = ballArrow.points()[1];
    const pos = stage.getPointerPosition();
    const minY = this.touchMove ? 70 : 30;
    const maxY = this.touchMove ? 390 : 370;
    const posY = this.touchOffset(this.clamp(pos.y, minY, maxY));
    const restrictedX = this.clamp(pos.x, 30, 325);
    const points = [ballArrowX, ballArrowY, restrictedX, posY];

    // This section calculates the angle of the original triangle
    // along with the slope of the line. That information
    // is used to get the new X and Y coordinates where the ball
    // will be placed.
    const deltaY = posY - ballArrowY;
    const deltaX = pos.x - ballArrowX;
    const angle = Math.atan(deltaY / deltaX) || 0;
    const ballX = 20 * Math.cos(angle);
    const ballY = 20 * Math.sin(angle);

    const ballPositionX = this.clamp(
      ballArrowX + deltaX + (deltaX < 0 ? -ballX : ballX),
      10,
      340
    );

    const ballPositionY = this.clamp(
      ballArrowY + deltaY + (deltaX < 0 ? -ballY : ballY),
      10,
      390
    );

    ball.x(ballPositionX);
    ball.y(ballPositionY);

    ballArrow.points(points);
    layer.batchDraw();
  }
}
