import Konva from 'konva';
import { defaultPlayers, runnerPositions } from '../assets/player_positions';
import HistoryState from './history_state';
import DefensivePlayer from './defensive_player';
import OffensivePlayer from './offensive_player';
import Ball from './ball';

const canvasWidth = 350;
const canvasHeight = 400;
const fieldWidth = 292;
const fieldHeight = 250;

export default class PlayCreator {
  constructor(fieldImage, canvas, endDrawBall) {
    this.storedCanvas = Object.keys(canvas).length > 0 ? canvas : null;
    this.stage = this.setStage();
    this.baseLayer = this.setBaseLayer();
    this.layer = this.setLayer();
    this.fieldImage = fieldImage;
    this.runners = { '1B': null, '2B': null, '3B': null, LH: null, RH: null };
    this.drawingBall = false;
    this.history = new HistoryState();
    this.players = [];
    this.balls = [];
    this.endDrawBall = endDrawBall;

    this.setPlay();
    this.setHistoryListeners();
    this.maybeSetListeners();
  }

  get stageJSON() {
    return this.stage.toJSON();
  }

  /*
   * Konva Canvas Section
   *
   * This section handles the creation of the canvas along with setting
   * the layers that objects are drawn to. There are two layers:
   *  - Base layer contains the background color and field image
   *  - Top layer contains all players and balls
   */

  setStage() {
    return this.storedCanvas
      ? this.createScaledStage()
      : new Konva.Stage({
          container: 'container',
          width: canvasWidth,
          height: canvasHeight,
        });
  }

  setBaseLayer() {
    return this.storedCanvas ? this.stage.children[0] : new Konva.Layer();
  }

  setLayer() {
    return this.storedCanvas && this.stage.children[1]
      ? this.stage.children[1]
      : new Konva.Layer();
  }

  createScaledStage() {
    const stage = Konva.Node.create(this.storedCanvas, 'container');
    // const scale = canvasWidth / this.storedCanvas.attrs.width;

    // This is setting the width to the updated sizing for now
    // When we add an ability to scale, the variables below will
    // change to the stage width * scale
    stage.width(canvasWidth);
    stage.height(canvasHeight);
    // stage.scale({ x: scale, y: scale });

    return stage;
  }

  hydrateBallGroup(groupId, group = null) {
    const ballNumber = groupId.split(":")[1];
    if (this.balls.find(ball => ball.id == ballNumber)) return;

    const attrs = {
      id: ballNumber || 1,
      playCreator: this,
    };
    if (group) attrs['group'] = group;

    const ball = new Ball(attrs);
    this.balls.push(ball);

    if (group) {
      this.layer.add(ball.group);
      ball.reset(group);
    }
  }

  // This resets the listeners when a canvas is loaded in
  maybeSetListeners() {
    if (!this.storedCanvas) return;

    const layerJSON = this.layer.toJSON();
    this.layer.destroyChildren();

    const layer = JSON.parse(layerJSON);

    layer.children.forEach((child) => {
      const group = Konva.Node.create(child);
      const groupId = group.getAttr('id');

      if (group.getAttr('name') == 'ballGroup') {
        this.hydrateBallGroup(groupId, group);
      } else if (['1BR', '2BR', '3BR', 'RH', 'LH'].includes(groupId)) {
        const player = new OffensivePlayer({
          position: groupId,
          positionText: 'R',
          x: group.x(),
          y: group.y(),
          playCreator: this,
          visible: group.isVisible(),
          group: group,
        });

        player.switchDrag();
        this.players.push(player);
        this.layer.add(player.group);
        this.runners[groupId] = player;
      } else {
        const player = new DefensivePlayer({
          position: groupId,
          x: group.x(),
          y: group.y(),
          playCreator: this,
          group: group,
        });

        player.switchDrag();
        this.players.push(player);
        this.layer.add(player.group);
      }
    });

    this.hydrateBallGroup("ballGroup:1");
    this.hydrateBallGroup("ballGroup:2");
    this.hydrateBallGroup("ballGroup:3");
  }

  /*
   * Layer object setup section
   *
   * This section handles the actual placement of items into the
   * canvas layers. The setPlay function initiates each of the action
   * items that needs to take place and is called in the constructor
   */

  setPlay() {
    this.drawField();
    this.drawBackground();
    this.placeInitialPosition();
    this.stage.add(this.baseLayer, this.layer);
  }

  drawField() {
    Konva.Image.fromURL(this.fieldImage, (imageNode) => {
      this.baseLayer.add(imageNode);
      imageNode.setAttrs({
        x: (canvasWidth - fieldWidth) / 2,
        y: canvasHeight - fieldHeight - 50,
      });
    });
  }

  drawBackground() {
    if (this.storedCanvas) return;

    const box = new Konva.Rect({
      width: this.stage.attrs.width,
      height: this.stage.attrs.height,
      fill: '#2F354D',
    });

    this.baseLayer.add(box);
  }

  placeInitialPosition() {
    if (this.storedCanvas) return;

    [1, 2, 3].forEach((id) => {
      this.createBall(id);
    });

    defaultPlayers.forEach((player) => {
      this.createDefensivePlayer(player);
    });

    ['1BR', '2BR', '3BR', 'LH', 'RH'].forEach((positionName) => {
      const player = runnerPositions[positionName];
      this.createOffensivePlayer(positionName, player);
    });
  }

  /*
   * History Section
   *
   * This section handles the undo/redo actions
   * When doing an undo/redo we:
   *  - Get the top layer from the history object
   *  - Destroy the current layers children
   *  - Cycle through the history layer and reset the listeners on each object
   *  - Add the player objects to the canvas
   */

  setHistoryListeners() {
    this.history.setHistory(this.layer.toJSON());

    this.stage.on('dragend', () => {
      this.history.setHistory(this.layer.toJSON());
    });
  }

  undo() {
    const lastState = this.history.undo();

    if (lastState) {
      this.resetLayerFromJson(lastState);
    }
  }

  redo() {
    const lastState = this.history.redo();

    if (lastState) {
      this.resetLayerFromJson(lastState);
    }
  }

  resetLayerFromJson(layerJSON) {
    if (layerJSON) {
      this.layer.destroyChildren();
      JSON.parse(layerJSON).children.forEach((child) => {
        this.resetGroupListeners(child);
      });
    }
  }

  // This is called on undo/redo
  resetGroupListeners(layerChild) {
    const group = Konva.Node.create(layerChild);
    const groupId = group.getAttr('id');

    if (group.getAttr('name') == 'ballGroup') {
      this.balls.forEach((ball) => {
        if (ball.group.getAttr('id') !== groupId) return;

        ball.reset(group);
      });
    } else {
      this.players.forEach((player) => {
        if (player.group.getAttr('id') !== groupId) return;

        player.reset(group, this);
      });
    }

    this.layer.add(group);
  }

  /*
   * Toggle Section
   *
   * This section handles the visibility of various objects
   */

  toggleDrawingBall() {
    this.drawingBall = !this.drawingBall;
    this.activeBall = 0;

    if (!this.drawingBall) {
      this.drawingBall = false;
      this.endDrawBall();
    }
  }

  setActiveBall(id) {
    this.activeBall = id;
  }

  deleteActiveBall() {
    this.balls.find(ball => ball.id == this.activeBall).deleteBall();
  }

  toggleOffensivePlayer(position) {
    const runner = this.runners[position];
    this.handleBatterToggle(position);

    runner.toggle();
    this.layer.draw();
    this.history.setHistory(this.layer.toJSON());
  }

  handleBatterToggle(position) {
    if (position == 'LH' && this.runners['RH'].visible) {
      this.runners['RH'].toggle();
    } else if (position == 'RH' && this.runners['LH'].visible) {
      this.runners['LH'].toggle();
    }
  }

  /*
   * Object creation section
   *
   * This area handles the logic for
   * creating players and the ball
   */

  createOffensivePlayer(positionName, { position, x, y }) {
    const player = new OffensivePlayer({
      position: positionName,
      positionText: position,
      x,
      y,
      playCreator: this,
    });

    this.addPlayerToCanvas(player);
    this.runners[positionName] = player;
  }

  createDefensivePlayer({ position, x, y }) {
    const player = new DefensivePlayer({
      position,
      x,
      y,
      playCreator: this,
    });

    this.addPlayerToCanvas(player);
  }

  createBall(id) {
    this.balls.push(
      new Ball({ id: id, playCreator: this })
    );
  }

  addPlayerToCanvas(player) {
    this.players.push(player);
    this.layer.add(player.group);
  }

  /*
   * Movement Change Section
   *
   * This area handles the logic for changing from
   * dragging a player to dragging an arrow
   */

  editPositionTwo() {
    this.players.forEach((player) => {
      player.switchIndicateMovement(this);
    });
  }

  editPositionOne() {
    this.players.forEach((player) => {
      player.switchDrag(this);
    });
  }
}
