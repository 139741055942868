import { BridgeComponent } from "@hotwired/strada"
import { BridgeElement } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "menu"
  static targets = [ "title", "item" ]

  show(event) {
    if (this.enabled) {
      event.stopImmediatePropagation()

      this.notifyBridgeToDisplayMenu(event)
    }
  }

  notifyBridgeToDisplayMenu(event) {
    const link = event.currentTarget.dataset["bridge-menu-link"] || event.currentTarget.dataset.bridgeMenuLink;
    console.log({ target: event.currentTarget, dataset: event.currentTarget.dataset, link })
    Turbo.visit(link)
  }

  makeMenuItems(elements) {
    const items = elements.map((element, index) => this.menuItem(element, index))
    const enabledItems = items.filter(item => item)

    return enabledItems
  }

  menuItem(element, index) {
    const bridgeElement = new BridgeElement(element)

    if (bridgeElement.disabled) return null

    return {
      title: bridgeElement.title,
      index: index,
      link: bridgeElement.bridgeAttribute("menu-link")
    }
  }

  logout(url) {

    const element = document.head.querySelector(`meta[name="${csrf-token}"]`)
    console.log(element)
    const csrf = element.getAttribute("content")

    const response = fetch(url, {
        method: 'DELETE',
        // credentials: "same-origin",
        headers: {
          "X-CSRF-Token": csrf,
          'Content-type': 'application/json'
        }
    });
    console.log(response)

    // Awaiting for the resource to be deleted
    const resData = 'resource deleted...';

    // Return response data
    return resData;
  }
}
