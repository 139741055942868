import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-visibility"
export default class extends Controller {
  static targets = ["passwordField", "hideIcon", "showIcon"];

  connect() {
    this.passwordVisible = false;
  }

  toggle() {
    if (this.passwordVisible) {
      this.hideIconTarget.classList.remove("hidden");
      this.showIconTarget.classList.add("hidden");
      this.passwordFieldTarget.type = "password";
      this.passwordVisible = false;
    } else {
      this.hideIconTarget.classList.add("hidden");
      this.showIconTarget.classList.remove("hidden");

      // We can make the password visible by switching the type of the input field.
      // See https://stackoverflow.com/a/22480122
      this.passwordFieldTarget.type = "text";

      this.passwordVisible = true;
    }
  }
}
