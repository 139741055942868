import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "maximumTemplate", "input", "addButton"];
  static values = { maximum: Number };

  add(e) {
    e.preventDefault();
    if (this.maxInputsReached) return;

    e.target.insertAdjacentHTML('beforebegin', this.templateTarget.innerHTML);
    if (this.hasInputTarget) {
      this.inputTargets[this.inputTargets.length - 1].focus();
    }

    this.disabledAddButtonIfMaxInputsReached();
  }

  remove(e) {
    e.preventDefault();
    e.target.closest(".row").remove();
  }

  disabledAddButtonIfMaxInputsReached() {
    if (this.maxInputsReached && this.hasAddButtonTarget) {
      this.addButtonTarget.disabled = true;

      this.addButtonTarget.insertAdjacentHTML('beforebegin', this.maximumTemplateTarget.innerHTML);
    }
  }

  get maxInputsReached() {
    return (this.inputTargets.length == this.maximumValue);
  }
}
